













import Vue from "vue";
import DataContainer from "@vuetifyx/common/DataContainer";
import coreApiClient from "@/services/apis/coreApiClient";

function mktUserQualityRevenueItems(label) {
  return {
    type: "XArrayInput",
    attrs: {
      label,
      xOptions: {
        content: {
          defaultExpandDisabled: true,
          itemProperties: {
            time: {
              type: "XDateTimePicker",
              attrs: {
                label: "Ngày bắt đầu",
                required: true,
              },
            },
            formula: {
              type: "textLarge",
              attrs: {
                label: "Công thức",
                required: true,
              },
            },
          },
          template: {
            formOptions: {
              content: {
                colLength: 12,
              },
            },
          },
        },
      },
    },
  };
}

export default Vue.extend({
  data() {
    return {
      dataContainer: new DataContainer(),
      formOptions: {
        attrs: {
          title: "Cài đặt",
        },
        content: {
          sections: {
            default: {
              fields: {
                mailHost: {
                  attrs: {
                    label: "Mail Host",
                  },
                },
                mailPort: {
                  type: "integer",
                  attrs: {
                    label: "Mail Port",
                  },
                },
                mailUsername: {
                  attrs: {
                    label: "Mail Username",
                  },
                },
                mailPassword: {
                  type: "password",
                  attrs: {
                    label: "Mail Password",
                  },
                },
                tiktokShopLoginUrl: {
                  attrs: {
                    label: "Tiktok Shop Login Url",
                  },
                },
                tiktokShopAppKey: {
                  attrs: {
                    label: "Tiktok Shop App Key",
                  },
                },
                tiktokShopAppSecret: {
                  attrs: {
                    label: "Tiktok Shop App Secret",
                  },
                },
                lazadaLoginUrl: {
                  attrs: {
                    label: "Lazada Login Url",
                  },
                },
                lazadaAppKey: {
                  attrs: {
                    label: "Lazada App Key",
                  },
                },
                lazadaAppSecret: {
                  attrs: {
                    label: "Lazada App Secret",
                  },
                },
                shopeeLoginUrl: {
                  attrs: {
                    label: "Shopee Login Url",
                  },
                },
                shopeeAppKey: {
                  attrs: {
                    label: "Shopee App Key",
                  },
                },
                shopeeAppSecret: {
                  attrs: {
                    label: "Shopee App Secret",
                  },
                },
                shopeeAdsLoginUrl: {
                  attrs: {
                    label: "Shopee Ads Login Url",
                  },
                },
                shopeeAdsAppKey: {
                  attrs: {
                    label: "Shopee Ads App Key",
                  },
                },
                shopeeAdsAppSecret: {
                  attrs: {
                    label: "Shopee Ads App Secret",
                  },
                },
                loginDeviceLimit: {
                  type: "integer",
                  attrs: {
                    label: "Login Device Limit",
                  },
                },
                loginDeviceLimitMobile: {
                  type: "integer",
                  attrs: {
                    label: "Login Device Limit (Mobile)",
                  },
                },
                mktCostLockTime: {
                  type: "XDateTimePicker",
                  attrs: {
                    label: "Thời gian khoá chi phí MKT",
                    xOptions: {
                      content: {
                        resetable: true,
                      },
                    },
                  },
                },
                adsCostLockTime: {
                  type: "XDateTimePicker",
                  attrs: {
                    label: "Thời gian khoá chi phí QC",
                    xOptions: {
                      content: {
                        resetable: true,
                      },
                    },
                  },
                },
                purchaseCommandLockTime: {
                  type: "XDateTimePicker",
                  attrs: {
                    label: "Thời gian khoá mua hàng",
                    xOptions: {
                      content: {
                        resetable: true,
                      },
                    },
                  },
                },
                orderLockTime: {
                  type: "XDateTimePicker",
                  attrs: {
                    label: "Thời gian khoá order",
                    xOptions: {
                      content: {
                        resetable: true,
                      },
                    },
                  },
                },
                productPurchaseAlertDays: {
                  type: "integer",
                  attrs: {
                    label: "Số ngày cảnh báo nhập hàng",
                  },
                },
                purchaseCommandAlertDays: {
                  type: "integer",
                  attrs: {
                    label: "Số ngày cảnh báo mua hàng",
                  },
                },
                blockedCustomerIps: {
                  type: "textMultiple",
                  attrs: {
                    label: "Danh sách IP bị chặn",
                  },
                },
                blockedCustomerNames: {
                  type: "textMultiple",
                  attrs: {
                    label: "Danh sách tên KH bị chặn",
                  },
                },
                blockedCustomerPhones: {
                  type: "textMultiple",
                  attrs: {
                    label: "Danh sách SĐT KH bị chặn",
                  },
                },
                recentCoincideCustomerIpInDay: {
                  type: "integer",
                  attrs: {
                    label: "Số ngày chặn trùng IP",
                  },
                },
                ladiPageOrderCountAlertItems: {
                  type: "XArrayInput",
                  attrs: {
                    label: "Cảnh báo SL đơn hàng LadiPage",
                    xOptions: {
                      content: {
                        defaultExpandDisabled: true,
                        itemProperties: {
                          startHour: {
                            type: "integer",
                            attrs: {
                              label: "Giờ bắt đầu",
                              required: true,
                            },
                          },
                          endHour: {
                            type: "integer",
                            attrs: {
                              label: "Giờ kết thúc",
                              required: true,
                            },
                          },
                          threshold: {
                            type: "integer",
                            attrs: {
                              label: "Cảnh báo nếu số lượng đơn / 20ph nhỏ hơn",
                              required: true,
                            },
                          },
                        },
                        template: {
                          formOptions: {
                            content: {
                              colLength: 4,
                            },
                          },
                        },
                      },
                    },
                  },
                },
                shippingCompanyStatisticsAlertAItems: {
                  type: "XArrayInput",
                  attrs: {
                    label: "Màu cảnh báo màn báo cáo ĐVVC",
                    xOptions: {
                      content: {
                        itemProperties: {
                          goal: {
                            type: "integer",
                            attrs: {
                              label: "Phần trăm",
                              required: true,
                            },
                          },
                          color: {
                            type: "color",
                            attrs: {
                              label: "Màu",
                              required: true,
                            },
                            ext: {
                              defaultValue: "#fff",
                            },
                          },
                        },
                        defaultExpandDisabled: true,
                      },
                    },
                  },
                },
                smsApiKey: {
                  attrs: {
                    label: "SMS Api Key",
                  },
                },
                smsTemplateItems: {
                  type: "XArrayInput",
                  attrs: {
                    label: "SMS Templates",
                    xOptions: {
                      content: {
                        itemLabel: (item) => {
                          return item.name;
                        },
                        itemProperties: {
                          name: {
                            attrs: {
                              label: "Tên",
                              required: true,
                            },
                          },
                          content: {
                            type: "textLarge",
                            attrs: {
                              label: "Nội dung",
                              required: true,
                            },
                          },
                        },
                        defaultExpandDisabled: true,
                        template: {
                          formOptions: {
                            content: {
                              colLength: 12,
                            },
                          },
                        },
                      },
                    },
                  },
                },
                smsSenderNames: {
                  type: "textMultiple",
                  attrs: {
                    label: "SMS Sender Names",
                  },
                },
                profitPublicStartMonth: {
                  type: "XDateTimePicker",
                  attrs: {
                    label: "Tháng mở BCLN (bắt đầu)",
                  },
                },
                profitPublicEndMonth: {
                  type: "XDateTimePicker",
                  attrs: {
                    label: "Tháng mở BCLN (kết thúc)",
                  },
                },
                mktUserQualityRevenueItems: mktUserQualityRevenueItems("Công thức tính doanh số hiệu quả MKT"),
                tmdtMktUserQualityRevenueItems: mktUserQualityRevenueItems("Công thức tính doanh số hiệu quả MKT (TMDT)"),
                incomeItemSettings: {
                  type: "XArrayInput",
                  attrs: {
                    label: "Cài đặt loại income",
                    xOptions: {
                      content: {
                        defaultExpandDisabled: true,
                        itemProperties: {
                          time: {
                            type: "XDateTimePicker",
                            attrs: {
                              label: "Ngày bắt đầu",
                              required: true,
                            },
                          },
                          allowedNames: {
                            type: "textMultiple",
                            attrs: {
                              label: "Loại income được cho phép",
                              required: true,
                            },
                          },
                        },
                        template: {
                          formOptions: {
                            content: {
                              colLength: 12,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          actionButtons: {
            save: {
              content: {
                text: "Lưu cài đặt",
              },
              on: {
                async xClick({ self }) {
                  const form = self.context();
                  const data = form.getData();
                  form.loading.value = true;
                  self.loading.value = true;
                  await coreApiClient.call("setting", "update", undefined, {
                    data,
                  });
                  self.loading.value = false;
                  form.loading.value = false;
                  form.success.value = "Lưu cài đặt thành công";
                },
              },
            },
          },
        },
      },
      formContainer: new DataContainer(),
    };
  },
  async created() {
    const data = await coreApiClient.call("setting", "get");
    this.dataContainer.value = data;
  },
});
